.react-datepicker {
  border: none !important;
  border-radius: 16px !important;
  box-shadow: 0px 4px 16px 0px var(--color-calender-shadow) !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 16px !important;
}

.react-datepicker__header {
  padding: 0 !important;
  background-color: var(--color-dark-green) !important;
  /* border: none !important; */
  border-radius: 16px !important;
  position: relative !important;
  text-align: center !important;
}

.react-datepicker__day-names {
  background-color: var(--color-white) !important;
}

.react-datepicker__day-name {
  color: var(--color-grey) !important;
  font-size: 12px !important;
}

.react-datepicker__day--today {
  color: var(--color-green) !important;
}

.react-datepicker__day--selected {
  background: var(--color-green) !important;
  border-radius: 50% !important;
  color: var(--color-white) !important;
}

.react-datepicker__day--keyboard-selected {
  background: var(--color-green) !important;
  border-radius: 50% !important;
  color: var(--color-white) !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
}

/* .react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  visibility: hidden;
} */
